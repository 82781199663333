import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectNota (Vue, filter, search, sorter, page, id, idtabla, esTecnico, esComercial, esVigilante, extra = []) {
    const apiFilter = Vue.$online.nota.buildApiFilterVisualizacion(id, idtabla, esTecnico, esComercial, esVigilante)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.nota.searchFilter(search))
    }
    let filtroAUsar = apiFilter
    if (extra.length > 0) {
      const apiFilterExtra = new APIFilter()
      for (const extraFilter of extra) {
        const apiFilterExtraTabla = Vue.$online.nota.buildApiFilterVisualizacion(extraFilter.id, extraFilter.idtabla, esTecnico, esComercial, esVigilante)
        apiFilterExtra.addNestedFilter(apiFilterExtraTabla)
      }
      if (search) {
        apiFilterExtra.addNestedFilter(Vue.$online.nota.searchFilter(search))
      }
      filtroAUsar = new APIFilter()
      filtroAUsar
        .setOperator(API_FILTER_OPERATORS.OR)
        .addNestedFilter(apiFilter)
        .addNestedFilter(apiFilterExtra)
    }

    const resp = await Vue.$api.call(
      'nota.select', {
        page,
        filter: filtroAUsar,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectNotaRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idnota', pks)
    const resp = await Vue.$api.call('nota.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}

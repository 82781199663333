<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-content>
      <v-list-item-title
        class="text-wrap"
      >
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="text-wrap"
      >
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        class="text-wrap"
      >
        {{ formattedItem.extraSubtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="mostrarDescripcionTabla"
        class="text-wrap"
      >
        <v-chip
          label
          color="info"
          dark
          small
          class="ml-1 mt-1"
        >
          {{ formattedItem.descripcionTabla }}
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    mostrarDescripcionTabla: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.titulo
      item.subtitle = this.$options.filters.shortDateTime(item.fnota)
      item.extraSubtitle = item.nota_texto
      item.descripcionTabla = this.$options.filters.capitalize(this.$online.tabla.getTitleSingularById(item.idtabla))
      return item
    }
  },
}
</script>
